import {CompleteDtcApplicationRequest} from "data/types/CompleteDtcApplicationRequest"
import {StartDtcApplicationRequest} from "data/types/StartDtcApplicationRequest"
import ApiClient, {IApiClient} from "./ApiClient"
import {StartDtcApplicationResponse} from "data/types/StartDtcApplicationResponse"
import {UpdateDtcApplicationRequest} from "data/types/UpdateDtcApplicationRequest"
import {UpdateDtcApplicationResponse} from "data/types/UpdateDtcApplicationResponse"
import {IDtcApplication} from "models/IDtcApplication"

const urlSubPath = "/applications"
export default class ApplicationApi {
  private apiClient: IApiClient

  public constructor() {
    this.apiClient = new ApiClient(process.env.REACT_APP_API_BASE_URL || "")
  }

  public getApplications = async (): Promise<IDtcApplication[]> => {
    const data = await this.apiClient.get<IDtcApplication[]>(`${urlSubPath}`)
    return data
  }

  public getApplication = async (code: string): Promise<IDtcApplication> => {
    const data = await this.apiClient.get<IDtcApplication>(
      `${urlSubPath}/${code}`
    )
    return data
  }

  public getApplicationPdfUrl = async (code: string): Promise<string> => {
    const data = await this.apiClient.get<string>(`${urlSubPath}/${code}/pdf`)
    return data
  }

  public startDtcApplication = async (
    body: StartDtcApplicationRequest
  ): Promise<StartDtcApplicationResponse> => {
    const data = await this.apiClient.post<
      StartDtcApplicationRequest,
      StartDtcApplicationResponse
    >(`${urlSubPath}`, body)
    return data
  }

  public startDtcApplicationV2 = async (
    sessionId: string
  ): Promise<StartDtcApplicationResponse> => {
    const data = await this.apiClient.post<
      {sessionId: string},
      StartDtcApplicationResponse
    >(`${urlSubPath}`, {sessionId})
    return data
  }

  public updateDtcApplication = async (
    body: UpdateDtcApplicationRequest
  ): Promise<UpdateDtcApplicationResponse> => {
    const data = await this.apiClient.put<
      UpdateDtcApplicationRequest,
      UpdateDtcApplicationResponse
    >(`${urlSubPath}/${body.dtcApplication.id}/update`, body)
    return data
  }

  public completeDtcApplication = async (
    body: CompleteDtcApplicationRequest
  ): Promise<UpdateDtcApplicationResponse> => {
    const data = await this.apiClient.put<
      CompleteDtcApplicationRequest,
      UpdateDtcApplicationResponse
    >(`${urlSubPath}/${body.applicationCode}/complete`, body, {timeout: 30000})
    return data
  }

  public emailDtcApplication = async (body: {
    applicationCode: string
  }): Promise<{success: boolean}> => {
    const data = await this.apiClient.put<
      {
        applicationCode: string
      },
      {success: boolean}
    >(`${urlSubPath}/${body.applicationCode}/email`, body, {timeout: 20000})
    return data
  }

  public startWalkThroughApplication = async (
    code: string
  ): Promise<{applicationCode: string}> => {
    const data = await this.apiClient.post<
      {code: string},
      {applicationCode: string}
    >(`${urlSubPath}/start-walk-through-application`, {code})
    return data
  }
}
