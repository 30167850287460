import ApiClient, {IApiClient} from "./ApiClient"
import {QuestionsRequest} from "data/types/QuestionsRequest"
import {IQuestion} from "models/IQuestion"

const urlSubPath = "/questions"
export default class QuestionApi {
  private apiClient: IApiClient

  public constructor() {
    this.apiClient = new ApiClient(process.env.REACT_APP_API_BASE_URL || "")
  }

  public getQuestions = async (
    filter: QuestionsRequest
  ): Promise<IQuestion[]> => {
    const data = await this.apiClient.getWithBodyFilter<
      QuestionsRequest,
      IQuestion[]
    >(`${urlSubPath}`, filter)
    return data
  }
}
