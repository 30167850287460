import ApplicationApi from "./ApplicationApi"
import AuthApi from "./AuthApi"
import EligibilityQuestionApi from "./EligibilityQuestionApi"
import PaymentApi from "./PaymentApi"
import ProductApi from "./ProductApi"
import QuestionApi from "./QuestionApi"
import SupportApi from "./SupportApi"
import UserApi from "./UserApi"

export const eligibilityQuestionApi = new EligibilityQuestionApi()
export const paymentApi = new PaymentApi()
export const applicationApi = new ApplicationApi()
export const questionApi = new QuestionApi()
export const userApi = new UserApi()
export const authApi = new AuthApi()
export const supportApi = new SupportApi()
export const productApi = new ProductApi()
